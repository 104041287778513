import React from "react";

const CellExpanderFormatter = ({ tabIndex, expanded, onCellExpand }) => {
  function handleKeyDown(e: React.KeyboardEvent<HTMLSpanElement>) {
    if (e.key === " " || e.key === "Enter") {
      e.preventDefault();
      onCellExpand();
    }
  }

  return (
    <div className={"exp"}>
      <span onClick={onCellExpand} onKeyDown={handleKeyDown}>
        <span tabIndex={tabIndex}>{expanded ? "\u25BC" : "\u25B6"}</span>
      </span>
    </div>
  );
};

export default CellExpanderFormatter;
